import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

interface AddressCardProps {
  address: {
    name: string;
    email: string;
    phone: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zip: string;
  };
}

const AddressCard: React.FC<AddressCardProps> = ({ address }) => {
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h5" component="h2" gutterBottom>
          Address
        </Typography>
        <Typography variant="body1">
          {address.name}<br />
          {address.email}<br />
          {address.phone}<br />
          {address.addressLine1}<br />
          {address.addressLine2 && `${address.addressLine2}`}<br />
          {address.city}, {address.state}, {address.zip}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default AddressCard;