import { BaseHttpService } from "./base-http.service";
import { urls } from "../constants/urls";

export type CartDto = {
  emailId: string;
  items: CartItemDto[];
};

export type CartItemDto = {
  name: string;
  description: string;
  price: number;
  img: string;
  quantity: number;
  type: string;
  size: string | null;
};

export default class CartHttpService extends BaseHttpService {
  constructor(baseUrl: string) {
    super(baseUrl);
  }
}

export class CartService {
  private httpClient: CartHttpService;

  constructor(orderApiBaseUrl: string) {
    this.httpClient = new CartHttpService(orderApiBaseUrl);
  }

  async cartDetails(formData: CartDto) {
    try {
      return await this.httpClient.post<CartDto>(urls.SAVE_CART_DETAILS, formData);
    } catch (error) {
      console.error("Error saving order details:", error);
      throw error;
    }
  }

  async getCartDetails(email: string) {
    try {
      const carts = await this.httpClient.get<any>(`${urls.GET_CART_DETAILS}/${email}`);
      if(carts.success) {
        return carts.data
      } else{
        return []
      }
    } catch (error) {
      console.error("Error saving order details:", error);
      throw error;
    }
  }
}
