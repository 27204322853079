import { useState } from 'react';
import { TextField, Button, Container, Grid, Typography } from '@mui/material';
import { ProductService } from '../service/product.service'

const productService = new ProductService(process.env.REACT_APP_BE_BASE_URL || '');
function ProductForm() {
  const [product, setProduct] = useState({
    name: '',
    description: '',
    price: 1000,
    img: '',
    quantity: 1,
    type: '',
  });

const bulkUpload = () => {
  // const p = [
  // {  "name":"UnStiched Suit", "description":"Raw Silk", "price": 2200, "img": "us14.png", "quantity": 1,  "type":  "unstiched" },
  // {  "name":"UnStiched Suit", "description":"Raw Silk", "price": 2400, "img": "us15.png", "quantity": 1,  "type":  "unstiched" },
  // { "name":"UnStiched Suit", "description":"Georgette Suit", "price": 3000, "img": "us16.png", "quantity": 1,  "type":  "unstiched" },
  // { "name":"UnStiched Suit", "description":"Georgette Suit", "price": 3000, "img": "us17.png", "quantity": 0,  "type":  "unstiched" },
  // { "name":"UnStiched Suit", "description":"Crepe Suit", "price": 4500, "img": "us18.png", "quantity": 1,  "type":  "unstiched" },
  // ]
  // p.map((val) => {
  // productService.saveProduct(val)
  // })
}

  const handleChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    
    setProduct({
      ...product,
      [name]: (name === 'price' || name === 'quantity') ? +value : value,
    });
  };

  const handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    // Your logic to save the product goes here
    console.log(product);
    
    productService.saveProduct(product)
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Add New Product
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Name"
              name="name"
              required
              value={product.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              label="Description"
              name="description"
              value={product.description}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              type="number"
              label="Price"
              name="price"
              value={+product.price}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label="Image URL"
              name="img"
              value={product.img}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              type="number"
              label="Quantity"
              name="quantity"
              value={+product.quantity}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label="Type"
              name="type"
              value={product.type}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Button type="submit" variant="contained" color="primary">
          Save Product
        </Button>
        <Button type="button" onClick={bulkUpload} variant="contained" color="primary">
        Bulk Upload
        </Button>
        
      </form>
    </Container>
  );
}

export default ProductForm;
