import { ReactNode } from "react";
import { Toolbar, Box, styled } from "@mui/material";

import { Header } from "./Header";
import { Drawer } from "./Drawer";
import { Footer } from "./Footer";
import { Main } from "./Main";
import { SortingAndFilter } from './Filters';
import { useLocation } from "react-router-dom";

import { DrawerContextProvider } from "../contexts/drawer-context";

const OuterContainer = styled(Box)`
  display: flex;
  overflow: hidden;
  height: inherit;
  flex-direction: column;
  min-height: 100vh;
`;

const InnerContainer = styled(Box)`
  display: flex;
  flex: 1;
  overflow: hidden;
  height: inherit;
`;

interface ILayoutProps {
  children: NonNullable<ReactNode>;
}

export const Layout = ({ children }: ILayoutProps) => {
  const location = useLocation();
  const cond = location.pathname == '/dashboard';
  console.log('location',location)
  return (
  <DrawerContextProvider>
    <OuterContainer>
      <Header />
      { cond && <SortingAndFilter />}
      <Toolbar />
      <InnerContainer>
        <Drawer />
        <Main>{children}</Main>
      </InnerContainer>
      <Footer>Adya Fashions</Footer>
    </OuterContainer>
  </DrawerContextProvider>
)};
