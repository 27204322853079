import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FormControl, InputLabel } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../store/store';
import { add, addType } from '../store/sorting';

export const SortingAndFilter: React.FC = () => {
  const sortBy = useSelector((state: RootState) => state.sorting);

  const dispatch = useDispatch<AppDispatch>();

  const setSorting = (e: SelectChangeEvent<string>) => {
    dispatch(add({
      sorting: e.target.value,
      type: sortBy.type
    }))
  }

  const setType = (e: SelectChangeEvent<string>) => {
    dispatch(addType({
      type: e.target.value,
      sorting: sortBy.sorting
    }))
  }
  
  return (
    <Paper elevation={3} style={{ position: 'sticky', top: '54px', zIndex: 9 }}>
      <Box sx={{display: 'flex'}}>
      <Box p={2} sx={{display: 'flex'}}>
        <Typography variant="h6">Sort By</Typography>
        <FormControl style={{ minWidth: '120px', marginLeft: '10px', display: 'flex', flexDirection: 'row' }}>
          <Select
            style={{ height: '25px', marginRight: '5px' }}
            defaultValue={sortBy.sorting || "relevance"}
            onChange={(e) => setSorting(e)}
          >
            <MenuItem value="relevance">Relevance</MenuItem>
            <MenuItem value="desc">Price: High to Low</MenuItem>
            <MenuItem value="asc">Price: Low to High</MenuItem>
            <MenuItem value="latest">Newest First</MenuItem>
          </Select>
          <strong>Type</strong>
          <Select
            style={{ height: '25px' }}
            defaultValue={sortBy.type || ""}
            value={sortBy.type || ""}
            onChange={(e) => setType(e)}
          >
            <MenuItem value="" selected>All</MenuItem>
            <MenuItem value="stiched">Stiched</MenuItem>
            <MenuItem value="unstiched">UnStiched</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {/* <Box p={2}>
        <Typography variant="h6">Filters</Typography>
      </Box> */}
      </Box>
    </Paper>
  );
};