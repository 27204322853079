import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { GetAuthorizationHeader } from "../utils/header.utils";
import { HttpResponse } from "../types/http-response.type";
import { httpMethod } from "../constants/http-methods";

export class BaseHttpService {
  private _httpClient: AxiosInstance;

  constructor(baseUrl: string, token?: string) {
    this._httpClient = axios.create({
      baseURL: baseUrl,
    });

    this._httpClient.interceptors.request.use((config) => {
      //config.headers["dapr-app-id"] = "orionapidapr";

      return config;
    });

    if (token) {
      this._httpClient.interceptors.request.use((config) => {
        config.headers.Authorization = GetAuthorizationHeader(token);

        return config;
      });
    }
  }

  public async get<T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<HttpResponse<T>> {
    return await this.call<T>(httpMethod.GET, url, undefined, config);
  }

  public async post<T>(
    url: string,
    body?: unknown,
    config?: AxiosRequestConfig
  ): Promise<HttpResponse<T>> {
    return await this.call<T>(httpMethod.POST, url, body, config);
  }

  public async put<T>(
    url: string,
    body?: unknown,
    config?: AxiosRequestConfig
  ): Promise<HttpResponse<T>> {
    return await this.call<T>(httpMethod.PUT, url, body, config);
  }

  public async patch<T>(
    url: string,
    body?: unknown,
    config?: AxiosRequestConfig
  ): Promise<HttpResponse<T>> {
    return await this.call<T>(httpMethod.PATCH, url, body, config);
  }

  public async delete<T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<HttpResponse<T>> {
    return await this.call<T>(httpMethod.DELETE, url, undefined, config);
  }

  private async call<T>(
    method: string,
    url: string,
    body?: unknown,
    config?: AxiosRequestConfig
  ): Promise<HttpResponse<T>> {
    try {
      const response = await this.sendRequest<HttpResponse<T>>(
        method,
        url,
        body,
        config
      );

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error?.response?.data) {
        return error.response.data;
      } else {
        return {
          success: false,
          statusCode: 400,
          message: error instanceof Error ? error.message : "Operation Failed",
        } as HttpResponse<T>;
      }
    }
  }

  private async sendRequest<T>(
    method: string,
    url: string,
    body?: unknown,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    switch (method) {
      case httpMethod.GET:
        return await this._httpClient.get(url, config);
      case httpMethod.POST:
        return await this._httpClient.post(url, body, config);
      case httpMethod.PUT:
        return await this._httpClient.put(url, body, config);
      case httpMethod.PATCH:
        return await this._httpClient.patch(url, body, config);
      case httpMethod.DELETE:
        return await this._httpClient.delete(url, config);
      default:
        return Promise.reject();
    }
  }
}
