import { Navigate, Route, Routes } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLoginProvider } from './components/GoogleLoginContext';
import { Layout } from './components';
import {
  DashboardPage,
  CartPage,
  ProductPage,
  ContactForm,
  MyWork,
  ConfirmOrderPage,
  PlaceOrderPage,
  LoginPage,
  ProductForm,
  MyOrders
} from './pages';

import { ROUTES } from './constants';

export const App = () => (
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
  <GoogleLoginProvider>
    <Layout>
      <Routes>
        <Route path="/" element={<Navigate to={ROUTES.main} />} />
        <Route path={ROUTES.main} element={<DashboardPage />} />
        <Route path={ROUTES.cart} element={<CartPage />} />
        <Route path={ROUTES.contact} element={<ContactForm />} />
        <Route path={ROUTES.product} element={<ProductPage />} /> 
        <Route path={ROUTES.myWork} element={<MyWork />} /> 
        <Route path={ROUTES.confirm} element={<ConfirmOrderPage />} /> 
        <Route path={ROUTES.placeorder} element={<PlaceOrderPage />} /> 
        <Route path={ROUTES.login} element={<LoginPage />} />
        <Route path={ROUTES.addProduct} element={<ProductForm />} />
        <Route path={ROUTES.myOrders} element={<MyOrders />} />
      </Routes>
    </Layout>
  </GoogleLoginProvider>
  </GoogleOAuthProvider>
);
