// MyOrders.js

import React from 'react';
import { useQuery } from 'react-query';
import { Card, CardContent, CardMedia, Typography, Divider, Grid } from '@mui/material';
import { OrderService } from '../service/order.service';
import { useGoogleLogin } from '../components/GoogleLoginContext';
import { NoOrderFound } from '../components/NoOrderFound';
import Loader from '../components/Loader';

const orderService = new OrderService(process.env.REACT_APP_BE_BASE_URL || '');  

interface OrderItem {
  _id: React.Key | null | undefined;
  name: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | null | undefined;
  description: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined;
  price: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined;
  quantity: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined;
  size: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined;
  img: string | undefined;
}

interface Order {
  orderId: string;
  currentStatus: string;
  emailId: string;
  createdOn: Date;
  updatedAt: Date;
  items: OrderItem[];
}

function MyOrders() {
  const { googleLoginState } = useGoogleLogin();

  const truncateOrderId = (orderId: string) => {
    if (orderId.length <= 20) return orderId;
    return orderId.substring(0, 20) + '...';
  };

  const { data: orders, isLoading, isError } = useQuery('orders', () => orderService.getOrderDetails(googleLoginState?.userData?.emailId || ''), {
    enabled: googleLoginState.isAuthenticated
  });

  
  if (isError) return <p>Error fetching data.</p>;
console.log(orders)
return (
  <Grid container spacing={3}>
    {isLoading && <Loader />}
      {!isLoading && orders && orders.length>0 && orders.map((order: Order) => (
        <Grid item xs={12} key={order.orderId}>
          <Card>
            <CardContent style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                <Typography variant="h6">
                  Order ID: <span title={order.orderId}>{truncateOrderId(order.orderId)}</span>
                </Typography>
                <Typography variant="body1">
                  Current Status: <b>{order.currentStatus}</b>
                </Typography>
                <Typography variant="body1">
                  Ordered On: {new Date(order.createdOn).toLocaleString()}
                </Typography>
                {order.items.map((item) => (
                  <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "flex-start", gap: "20px"}}>
                  <div key={item._id}>
                    <Typography variant="subtitle1">{item.name}</Typography>
                    <Typography variant="body2">
                      {item.description}
                    </Typography>
                    <Typography variant="body2">Size: {item.size}</Typography>
                  </div>
                  <div>
                    <img
                      key={item._id}
                      src={`${process.env.REACT_APP_BE_BASE_URL}${item.img}`}
                      alt="product img"
                      style={{ height: 'auto', maxWidth: '15rem' }}
                    />
                </div>
                </div>
                ))}
              </div>
            </CardContent>
          </Card>
        </Grid>
      ))}
      {
        !isLoading && orders && orders.length===0 && <NoOrderFound />
      }
    </Grid>
);
}

export default MyOrders;