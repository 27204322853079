import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import videos from './myWork.json'

// interface Video {
//     id: string;
//     title: string;
//     thumbnail: string;
// }

const MyWork: React.FC = () => {
    //const [videos, setVideos] = useState<Video[]>([]);

    // useEffect(() => {
    //     // Fetch video data from YouTube Data API
    //     fetch(
    //         https://www.googleapis.com/youtube/v3/search?key=YOUR_API_KEY&part=snippet&type=video&q=YOUR_SEARCH_QUERY&maxResults=6
    //     )
    //         .then((response) => response.json())
    //         .then((data) => {
    //             // Extract video information from the response
    //             const videoList: Video[] = data.items.map((item: any) => ({
    //                 id: item.id.videoId,
    //                 title: item.snippet.title,
    //                 thumbnail: item.snippet.thumbnails.medium.url,
    //             }));
    //             setVideos(videoList);
    //         })
    //         .catch((error) => console.error('Error fetching video data:', error));
    // }, []);

    return (
        <div>
            {/* <Typography variant="h4" sx={{ fontStyle: 'italic', color: '#132954' }}>
                Elevate Your Wardrobe with Our Latest Boutique Stitching Creations
            </Typography> */}
            <Typography variant="h6" gutterBottom sx={{color: "#D2268D"}}>
                Recent Work
            </Typography>
            <Grid container spacing={4}>
                {videos.data.map((video) => (
                    <Grid item xs={12} sm={6} md={4} key={video.id}>
                        <Paper elevation={3} style={{ padding: 10 }}>
                            <iframe 
                            width="100%"
                            height="250"
                            src={video.url} />
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default MyWork;