import { BaseHttpService } from "./base-http.service";
import { urls } from "../constants/urls";

export type OrderDetailsDto = {
  currentStatus: string;
  emailId: string;
  items: OrderItemDto[];
};

export type OrderItemDto = {
  name: string;
  description: string;
  price: number;
  img: string;
  quantity: number;
  type: string;
  size: string | null;
};

export default class OrderHttpService extends BaseHttpService {
  constructor(baseUrl: string) {
    super(baseUrl);
  }
}

export class OrderService {
  private httpClient: OrderHttpService;

  constructor(orderApiBaseUrl: string) {
    this.httpClient = new OrderHttpService(orderApiBaseUrl);
  }

  async getUserContactDetails(emailId: string) {
    try {
      return await this.httpClient.get<string>(`${urls.GET_ORDER_DETAILS}/${emailId}`);
    } catch (error) {
      console.error("Error retrieving contact details:", error);
      throw error;
    }
  }

  async orderDetails(formData: OrderDetailsDto) {
    try {
      return await this.httpClient.post<OrderDetailsDto>(urls.SAVE_ORDER_DETAILS, formData);
    } catch (error) {
      console.error("Error saving order details:", error);
      throw error;
    }
  }

  async getOrderDetails(email: string) {
    try {
      const orders = await this.httpClient.get<any>(`${urls.GET_ORDER_DETAILS}/${email}`);
      if(orders.success) {
        return orders.data
      } else{
        return []
      }
    } catch (error) {
      console.error("Error saving order details:", error);
      throw error;
    }
  }
}
