import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { GoogleLogin, googleLogout } from '@react-oauth/google';
import { useGoogleLogin } from '../components/GoogleLoginContext'; // Import the hook
import { UserService } from '../service/user.service';
import { Logout } from '@mui/icons-material';
import { ListItemIcon } from '@mui/material';
import { jwtDecode } from "jwt-decode";

const userService = new UserService(process.env.REACT_APP_BE_BASE_URL || '');
interface JwtPayload {
  given_name: string,
  family_name: string,
  name: string,
  email: string
}
export const AccountMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { googleLoginState, setGoogleLoginState } = useGoogleLogin(); // Use the hook to access Google login state
  const [userExist, setUserExist] = React.useState<boolean>(false);

  const open = Boolean(anchorEl);
  const userDataString = googleLoginState.userData;
  useEffect(() => {
    const storedToken = localStorage.getItem('googleToken');
    if (storedToken) {
      autoLogin(storedToken);
    }

    // if (userDataString) {
    //     setUserExist(true)
    //} 
  }, []);

  const { data } = useQuery('userDetails', () => userService.userDetails({
    username: userDataString?.name,
    email: userDataString?.emailId
  }), {
    enabled: !!userDataString?.emailId && !userExist
  });

  if(data) {
   // setUserExist(true)
   console.log('data--', data)
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const autoLogin = (token: any) => {
    try {
      fetch(`https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=${token}`)
      .then((response) => response.json())
      .then((data) => {
        console.log('data---', data)
        if(data?.error_description) {
          console.log('not valid token')
          localStorage.setItem('googleToken', '')
          setGoogleLoginState({
            isAuthenticated: false,
          });
        } else {
          setUserData(data);
        }
        
      })
      .catch((error) => {
        console.error('Error auto-logging in:', error);
      });
    } catch(e) {
      console.log('in error', e)
    }
    // Use the stored token to automatically log the user in
    
  };

  const setUserData = (googleUser: JwtPayload) => {
    const firstName = googleUser?.given_name;
    const lastName = googleUser?.family_name;

    const userData = { 
        name: googleUser?.name,
        emailId: googleUser?.email,
        firstName,
        lastName,
    };
    
    localStorage.setItem('userdata', JSON.stringify(userData));
    setGoogleLoginState({
        isAuthenticated: true,
        userData
    });
  }

  const handleSignInSuccess = async (tokenResponse: any) => {
    try{
    localStorage.setItem('googleToken', tokenResponse.credential);
    const googleUser = jwtDecode<JwtPayload>(tokenResponse.credential)
    setUserData(googleUser)
  } catch (e) {
    console.log('Error in decoding token', e)
  }
};

const handleLogoutSuccess = () => {
  setAnchorEl(null);
  googleLogout();
  setGoogleLoginState({
    isAuthenticated: false,
  });
  localStorage.removeItem('userdata')
};

  const handleSignInFailure = (error: any) => {
    console.log('Error signing in:', error);
  };
console.log('googleLoginState', googleLoginState)
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        
        {googleLoginState.isAuthenticated ? ( // Render different content based on authentication state
          <Tooltip title={`Account of ${userDataString?.name || 'Unknown'}`}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32, textTransform: 'uppercase' }}>
              {userDataString?.firstName?.charAt(0) || 'U'}
              {userDataString?.lastName?.charAt(0) || 'N'}
            </Avatar>
          </IconButton>
        </Tooltip>
        
        ) : (
            <GoogleLogin
              onSuccess={handleSignInSuccess}
              // @ts-ignore
              onError={handleSignInFailure}
              auto_select
            />
        )}
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleClose}>
          <Avatar /> Profile
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Avatar /> My account
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogoutSuccess}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
