import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';

interface UserState {
    name: string;
    emailId: string;
    firstName: string;
    lastName: string;
}

const getCookie = (name: string) => {
    const cookieString = document.cookie;
    const cookies = cookieString.split(';');
  
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Check if this is the cookie we're looking for
      if (cookie.startsWith(name + '=')) {
        // Return the value of the cookie
        return cookie.substring(name.length + 1);
      }
    }
    // If cookie not found, return null
    return null;
  };

  const userDataString: string | null = getCookie('userData');
  let userData = {}
    if (userDataString) {
        userData = JSON.parse(userDataString);
        
    } 
console.log('userData', userData)
const initialState: any = {
    ...userData,
};

const user = createSlice({
    name: 'user',
    initialState,
    reducers: {
        add: (state, action: PayloadAction<UserState>) => {
            console.log(state, action);
            console.log(current(state))
            //state[action.payload.emailId] = action
            return {
                ...state,
                currentUser: action.payload
            }
        },
        logout: (state, action: PayloadAction<UserState>) => {
           // delete state[action.payload.emailId]
            console.log(current(state))
        },
    },
});

export const { add, logout } = user.actions;
export default user.reducer;