export const ROUTES = {
  main: '/dashboard',
  cart: '/cart',
  contact: '/contact',
  product: '/product/:pid',
  myWork: 'mywork',
  confirm: 'confirm',
  placeorder: 'placeorder',
  login: 'login',
  addProduct: 'addProduct',
  myOrders: 'myOrders'
};
