import { BaseHttpService } from "./base-http.service";
import { urls } from "../constants/urls";

export default class ProductHttpService extends BaseHttpService {
  constructor(baseUrl: string) {
    super(baseUrl);
  }
}

export type ProductDetailsDto = {
  productId?: string;
  createdOn?: string | number | Date;
  name: string;
  description: string;
  price: number;
  img: string;
  quantity: number;
  type: string;
};

export class ProductService {
  private httpClient: ProductHttpService;

  constructor(productApiBaseUrl: string) {
    this.httpClient = new ProductHttpService(productApiBaseUrl);
  }

  async getProduct(productId: string) {
    return await this.httpClient.get<ProductDetailsDto>(`${urls.GET_PRODUCT}/${productId}`);
  }

  async getProductList() {
    return await this.httpClient.get<ProductDetailsDto>(`${urls.GET_PRODUCT}`);
  }

  async saveProduct(productData: ProductDetailsDto) {
    return await this.httpClient.post<ProductDetailsDto>(
      urls.SAVE_PRODUCT,
      { ...productData }
    );
  }
}
