import React, { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';

interface UserState {
  name: string;
  emailId: string;
  firstName: string;
  lastName: string;
}

interface GoogleLoginState {
  isAuthenticated: boolean;
  userData?: UserState; // Define UserData interface according to your data structure
}

interface GoogleLoginContextType {
  googleLoginState: GoogleLoginState;
  setGoogleLoginState: Dispatch<SetStateAction<GoogleLoginState>>;
}

interface GoogleLoginProviderProps {
  children: ReactNode;
}

const GoogleLoginContext = createContext<GoogleLoginContextType | undefined>(undefined);

export const useGoogleLogin = () => {
  const context = useContext(GoogleLoginContext);
  if (!context) {
    throw new Error('useGoogleLogin must be used within a GoogleLoginProvider');
  }
  return context;
};

export const GoogleLoginProvider: React.FC<GoogleLoginProviderProps> = ({ children }) => {
  const [googleLoginState, setGoogleLoginState] = useState<GoogleLoginState>({
    isAuthenticated: false
  });

  return (
    <GoogleLoginContext.Provider value={{ googleLoginState, setGoogleLoginState }}>
      {children}
    </GoogleLoginContext.Provider>
  );
};
