import React, { useEffect, useState, useMemo } from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Chip from '@mui/joy/Chip';
import { Link } from 'react-router-dom';
import Typography from '@mui/joy/Typography';
import Badge from '@mui/material/Badge';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../store/store';
import { add, selectProducts } from '../store/product';
import Dropdown from '@mui/joy/Dropdown';
import MenuButton from '@mui/joy/MenuButton';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import { SizeOptions } from './Size';
import { CartService } from '../service/cart.service';
import { useGoogleLogin } from './GoogleLoginContext';

interface Product {
  productId?: string | undefined;
  name: string;
  description: string;
  price: number;
  img: string;
  quantity: number;
  createdOn?: any;
  type: string;
  size?: string;
  count?: number;
}

interface ProductCardProps {
  product: Product;
}

const cartService = new CartService(process.env.REACT_APP_BE_BASE_URL || '');
export const ProductCard: React.FC<ProductCardProps> = React.memo(({ product }) => {
  const [productCount, setProductCount] = useState(0);
  const productExist = useSelector((state: RootState) => state.product.filter(({ productId }) => productId === product.productId));
  const { googleLoginState } = useGoogleLogin(); 
  const [productAdd, setAddProduct] = useState(false);
  
  useEffect(() => {
    let total = 0;
    if (productExist.length > 0) {
      productExist.forEach(({ count }) => (total += count));
    }
    if(total !== productCount) 
      setProductCount(total);
  }, [productExist]); // Include productExist as a dependency

  const dispatch = useDispatch<AppDispatch>();
  const products = useSelector(selectProducts);

  useEffect(() => {
    if(googleLoginState.userData?.emailId && productAdd) {
    cartService.cartDetails({
      emailId: googleLoginState.userData?.emailId || '',
      items: products || []
    });
    setAddProduct(false);
  }
  }, [products]);

  const getProduct = (product: Product) => {
    const encodedMessage = encodeURIComponent(`${window.location.hostname}/product/${product.productId}`);
    const whatsappUrl = `https://api.whatsapp.com/send?phone=+919876993646&text=${encodedMessage}`;
    window.open(whatsappUrl, '_blank');
  }  

  const handleSizeChange = (event: React.MouseEvent<HTMLElement>, product: Product, size = '') => {
    const target = event.target as HTMLInputElement;
    size = (size) ? size : target.value;
    dispatch(add({ ...product, count: 1, size }));
    setAddProduct(true);
  };

  return (
    <Card sx={{ width: 450, maxWidth: '100%', boxShadow: 'lg', minWidth: 300 }}>
      <CardOverflow>
        <AspectRatio ratio="10/15" objectFit="contain">
          <img
            src={`${process.env.REACT_APP_BE_BASE_URL}${product.img}`}
            srcSet={`${process.env.REACT_APP_BE_BASE_URL}${product.img}`}
            loading="lazy"
            alt=""
            height="200px"
          />
        </AspectRatio>
      </CardOverflow>
      <CardContent sx={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ flexGrow: 1 }}>
          <Typography level="body-xs">{product.description}</Typography>
        <Link
          to={`/product/${product.productId}`}
          color="neutral"
          style={{ fontWeight: "bold"}}
          
        >
          {product.name}
        </Link>
          <Typography level="title-lg" sx={{ mt: 1, fontWeight: 'xl' }} endDecorator={<Chip component="span" size="sm" variant="soft" color="success">Lowest price</Chip>}>
            &#8377; {product.price}
          </Typography>
          {product.quantity > 0 && <Typography level="body-sm"> (Only <b>{product.quantity}</b> left in stock!)</Typography>}
          {product.quantity === 0 && <Typography level="body-sm">Out of stock</Typography>}
        </div>
        <Badge badgeContent={productCount} color="primary" invisible={productCount === 0}>
          {product.type === 'stiched' && product.quantity > 0 && (
            <Dropdown>
              <MenuButton slots={{ root: Button }} slotProps={{ root: { variant: 'plain', color: 'neutral' } }} sx={{ alignItems: 'flex-start', paddingBlock: 'inherit', minWidth: 0, p: 0, m: 0, minHeight: 0, height: 0 }}>
                <Button variant="solid" size="sm" sx={{ height: '35px', width: '35px', background: '#132954', '&:hover': { backgroundColor: '#D2268D', color: 'common.white' } }}>
                  <AddShoppingCartIcon />
                </Button>
              </MenuButton>
              <Menu>
                <MenuItem>
                  <SizeOptions handleSizeChange={(event) => handleSizeChange(event, product)} />
                </MenuItem>
              </Menu>
            </Dropdown>
          )}
          {product.type === 'unstiched' && product.quantity > 0 &&  (
            <Button variant="solid" size="sm" sx={{ height: '35px', width: '35px', background: '#132954', '&:hover': { backgroundColor: '#D2268D', color: 'common.white' } }} 
            onClick={(event) => handleSizeChange(event, product, 'free size')
            //dispatch(add({ ...product, count: 1, size: 'free size' }))
          }>
              <AddShoppingCartIcon />
            </Button>
          )}
        </Badge>
        <Button variant="solid" size="sm" sx={{ height: '35px', width: '35px', background: '#D2268D', '&:hover': { backgroundColor: '#0DC243', color: 'common.white' } }} onClick={() => getProduct(product)}>
          <WhatsAppIcon />
        </Button>
      </CardContent>
    </Card>
  );
});