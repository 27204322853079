import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { useGoogleLogin } from '../components/GoogleLoginContext';
import { Products } from '../components/Products';


interface CartPageProps {
}

export const CartPage: React.FC<CartPageProps> = () => {
  const navigate = useNavigate();
  const cart = useSelector((state: RootState) => state.product)
  const { googleLoginState } = useGoogleLogin();

  const proceedToCheckOut = () => {
    if(!googleLoginState.isAuthenticated) {
      navigate('/login')
    } else {
      navigate('/contact');
    }
  }

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Your Cart
      </Typography>
      {cart.length === 0 ? (
        <Typography variant="body1">Your cart is empty</Typography>
      ) : (
        <>
        <Products showRemove = {true} />
        <Button
                style={{background: "#D2268D", color: "#fff"}}
                onClick={() => proceedToCheckOut()}
              >
                Place Order
              </Button>
        </>
      )}
    </div>
  );
};