import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';

const initialState: any = {
    sorting: 'relevance',
    type: ''
}

interface Payload {
    type: any;
    sorting: string;
  }

const sorting = createSlice({
    name: 'sorting',
    initialState,
    reducers: {
        add: (state, action: PayloadAction<Payload>) => {
            console.log(state, action);
            console.log(current(state))
            //state['sorting'] = action.payload
            return {
                ...state,
                sorting: action.payload.sorting
            }
        },
        addType: (state, action: PayloadAction<Payload>) => {
            return {
                ...state,
                type: action.payload.type
            }
        },
    },
});

export const { add, addType } = sorting.actions;
export default sorting.reducer;