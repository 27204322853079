import {
  Dashboard as DashboardIcon,
  ShoppingCart as ShoppingCartIcon,
  Checkroom as CheckroomIcon,
  ShoppingBasket as ShoppingBasketIcon
  // People as PeopleIcon,
  // AttachMoney as AttachMoneyIcon,
} from '@mui/icons-material';

import { IMenuItem } from '../types';
import { ROUTES } from './routes';

export const MENU_LIST: IMenuItem[] = [
  {
    route: ROUTES.main,
    literal: 'Dashboard',
    Icon: DashboardIcon,
  },
   {
    route: ROUTES.myWork,
    literal: 'Our Recent Work',
    Icon: CheckroomIcon,
  },
  {
    route: ROUTES.cart,
    literal: 'Cart',
    Icon: ShoppingCartIcon,
  },
 
  {
    route: ROUTES.myOrders,
    literal: 'My Orders',
    Icon: ShoppingBasketIcon,
  },
];
