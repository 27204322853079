import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';

interface ProductState {
    productId?: string | undefined;
    createdOn?: any; 
    name: string;
    description: string;
    price: number;
    img: string;
    quantity: number;
    count: number;
    size: string | null;
    type: string
}

const initialState: ProductState[] = [];

const product = createSlice({
    name: 'product',
    initialState,
    reducers: {
        add: (state, action: PayloadAction<ProductState>) => {
            const existingProduct = state.find(product => product.productId === action.payload.productId && product.size === action.payload.size);
            console.log('existingProduct', existingProduct, state, action, { ...action.payload, count: 1 });
            if (existingProduct) {
                existingProduct.count++;
            } else {
                state.push({ ...action.payload, count: 1, size: action.payload.size });
            }
            console.log('state++++',current(state));
           
        },
        remove: (state, action: PayloadAction<ProductState>) => {
            const index = state.findIndex(product => product.productId === action.payload.productId);
            if (index !== -1) {
                if (state[index].count > 1) {
                    state[index].count--;
                } else {
                    state.splice(index, 1);
                }
            }
        },
        removeAll: (state) => {
            state.length = 0
        },
        
    },
});

export const { add, remove, removeAll } = product.actions;
export default product.reducer;

export const selectProducts = (state: { product: ProductState[] }) => state.product;