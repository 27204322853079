import React, { useState } from 'react';
import { Button, Typography, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Products } from '../components/Products';
import { OrderService } from '../service/order.service';
import { useGoogleLogin } from '../components/GoogleLoginContext';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../store/store';
import { removeAll } from '../store/product';

const orderService = new OrderService(process.env.REACT_APP_BE_BASE_URL || '');
const ConfirmOrderPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const product = useSelector((state: RootState) => state.product)
  const [confirmDisable, setConfirmDisable] = useState(false);
  const { googleLoginState } = useGoogleLogin();
  if(!googleLoginState?.userData?.emailId) {
    navigate('/login')
  }
  const onConfirm = async() => {
    console.log('product', product)
    setConfirmDisable(true);
    const data = await orderService.orderDetails({
      currentStatus: "Processing",
      emailId: googleLoginState.userData?.emailId || '',
      items: product || []
    });
    if(data.success) {
      dispatch(removeAll())
      navigate('/placeorder');
    }
    setConfirmDisable(false)
  };
  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Confirm Place Order
      </Typography>
      <Typography variant="body1" gutterBottom>
        Are you sure you want to place this order?
      </Typography>
      <Products />
      <Button variant="contained" color="primary" onClick={onConfirm} disabled={confirmDisable}>
        Confirm Order
      </Button>
    </Container>
  );
};

export default ConfirmOrderPage;
