import React, { useState } from 'react';
import { FormControl, FormLabel, ToggleButtonGroup, ToggleButton, Button } from '@mui/material';
 
export function SizeOptions(props: { handleSizeChange: ((event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => void) | undefined; }) {
  return (
    <FormControl component="fieldset" >
      <FormLabel>Select Size</FormLabel>
      <ToggleButtonGroup
        exclusive
        onChange={props.handleSizeChange}
        aria-label="size options"
       
      >
        <ToggleButton value="S">S</ToggleButton>
        <ToggleButton value="M">M</ToggleButton>
        <ToggleButton value="L">L</ToggleButton>
        <ToggleButton value="XL">XL</ToggleButton>
        <ToggleButton value="XXL">XXL</ToggleButton>
      </ToggleButtonGroup>
    </FormControl>
  );
}