import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { Grid } from '@mui/material';
import { ProductCard } from '../components/ProductCard';
import Loader from '../components/Loader';
import { ProductService, ProductDetailsDto } from '../service/product.service';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { NoProductFound } from '../components/NoProductFound';

const productService = new ProductService(process.env.REACT_APP_BE_BASE_URL || '');

export const DashboardPage: React.FC = () => {
  const sortBy = useSelector((state: RootState) => state.sorting);

  const { data, isLoading } = useQuery('contactDetails', () => productService.getProductList());

  const filteredProducts = useMemo(() => {
    let sortedData: ProductDetailsDto[] = [];
    if (data && data.data && Array.isArray(data.data)) {
      sortedData = [...data.data];
    }

    if (sortBy.type !== '') {
      sortedData = sortedData.filter((product) => product.type === sortBy.type);
    }

    if (sortBy.sorting === 'asc') {
      sortedData.sort((a, b) => a.price - b.price);
    } else if (sortBy.sorting === 'desc') {
      sortedData.sort((a, b) => b.price - a.price);
    } else if (sortBy.sorting === 'latest') {
      sortedData.sort((a, b) => {
        const dateA = a.createdOn ? new Date(a.createdOn).getTime() : -Infinity;
        const dateB = b.createdOn ? new Date(b.createdOn).getTime() : -Infinity;
        return dateB - dateA;
      });
    }

    return sortedData;
  }, [sortBy, data]);

  return (
    <Grid container spacing={2}>
      {isLoading && <Loader />}
      {!isLoading && filteredProducts.length > 0 ? (
        filteredProducts.map((product) => (
          <Grid item xs={12} sm={6} md={4} key={product.productId}>
            <ProductCard product={product} />
          </Grid>
        ))
      ) : (
        <NoProductFound />
      )}
    </Grid>
  );
};
