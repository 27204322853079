import { Typography } from '@mui/material';
 
export function NoOrderFound() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh', marginLeft: '20px' }}>
      <div>
        <Typography variant="h5" gutterBottom>
          No order found
        </Typography>
        <Typography variant="body1">
          Sorry, we couldn't find any order. 
        </Typography>
      </div>
    </div>
  );
}