import React from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '../components/GoogleLoginContext';
import { jwtDecode } from "jwt-decode";

interface JwtPayload {
  given_name: string,
  family_name: string,
  name: string,
  email: string
}

const LoginPage: React.FC = () => {
  const { googleLoginState, setGoogleLoginState } = useGoogleLogin();
  const navigate = useNavigate();
  if(googleLoginState.isAuthenticated) {
    navigate('/confirm');
  }

  const handleLoginSuccess = (response: any) => {
    const googleUser = jwtDecode<JwtPayload>(response.credential)
    const firstName = googleUser?.given_name;
    const lastName = googleUser?.family_name;

    const userData = { 
        name: googleUser?.name,
        emailId: googleUser?.email,
        firstName,
        lastName,
    };
    localStorage.setItem('userdata', JSON.stringify(userData));
    localStorage.setItem('googleToken', response.credential);
    setGoogleLoginState({
      isAuthenticated: true,
      userData
    });
  };

  return (
    <div>
      <GoogleLogin
        onSuccess={handleLoginSuccess}
        // @ts-ignore
        onError={(error: any) => console.error('Login failed:', error)}
      />
    </div>
  );
};

export default LoginPage;
