import * as React from 'react';
import Box from '@mui/material/Box';
    
const Loader = () => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '88vh', width: '200vh', flexDirection: 'column' }}>
            <img src={process.env.PUBLIC_URL+'source.gif'} style={{ width: "250px" }} />
        </Box>
    );
}
    
export default Loader;