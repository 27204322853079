import { BaseHttpService } from "./base-http.service";
import { urls } from "../constants/urls";

export default class UserHttpService extends BaseHttpService {
  constructor(baseUrl: string) {
    super(baseUrl);
    console.log('baseURL', baseUrl)
  }
}

export type UserDetailsDto = {
    username?: string;
    email?: string;
  };
  
  export class UserService {
    private httpClient: UserHttpService;
  
    constructor(userApiBaseUrl: string) {
      this.httpClient = new UserHttpService(userApiBaseUrl);
    }
  
    async getLoggedUser() {
      return await this.httpClient.get<string>(urls.GET_LOGGED_USER);
    }
  
    async userDetails({
        username,
        email
    }: UserDetailsDto) {
      console.log('urls.SAVE_USER_DETAILS', urls.SAVE_USER_DETAILS)
      return await this.httpClient.post<UserDetailsDto>
      (
        urls.SAVE_USER_DETAILS,
        {
            username,
            email
        },
      );
    }
  }