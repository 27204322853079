import { BaseHttpService } from "./base-http.service";
import { urls } from "../constants/urls";

export default class ContactHttpService extends BaseHttpService {
  constructor(baseUrl: string) {
    super(baseUrl);
  }
}

export type ContactDetailsDto = {
  name: string;
  email: string;
  phone: string;
  addressLine1: string;
  addressLine2?: string; // Optional field
  city: string;
  state: string;
  zip: string;
  };
  
  export class ContactService {
    private httpClient: ContactHttpService;
  
    constructor(contactApiBaseUrl: string) {
      this.httpClient = new ContactHttpService(contactApiBaseUrl);
    }
  
    async getUserContactDetails(emailId: string) {
      return await this.httpClient.get<string>(`${urls.GET_CONTACT_DETAILS}/${emailId}`);
    }
  
    async contactDetails(formData: ContactDetailsDto) {
      return await this.httpClient.post<ContactDetailsDto>
      (
        urls.SAVE_CONTACT_DETAILS,
        {
            ...formData
        },
      );
    }
  }