import React, { useEffect, useState } from 'react';
import { Grid, Typography, Card, CardMedia, CardContent, CardActions, Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../store/store';
import { remove, selectProducts } from '../store/product';
import CloseIcon from '@mui/icons-material/Cancel';
import { CartService } from '../service/cart.service';
import { useGoogleLogin } from './GoogleLoginContext';

interface CartPageProps {
  showRemove?: boolean
}

const cartService = new CartService(process.env.REACT_APP_BE_BASE_URL || '');
export const Products: React.FC<CartPageProps> = ({ showRemove = false }) => {
  const cart = useSelector((state: RootState) => state.product);
  const dispatch = useDispatch<AppDispatch>();
  const { googleLoginState } = useGoogleLogin();

  const [productAdd, setAddProduct] = useState(false);

  let total = 0;
  const products = useSelector(selectProducts);

  useEffect(() => {
    if(googleLoginState.userData?.emailId && productAdd) {
    cartService.cartDetails({
      emailId: googleLoginState.userData?.emailId || '',
      items: products || []
    });
    setAddProduct(false);
  }
  }, [products]);

  const removeProduct = (product: any) => {
    dispatch(remove({...product }))
    setAddProduct(true);
  }

  return (      
    <Grid container spacing={2}>
      {cart.map((product) => {
        total = product.price * product.count + total;
        return (
          <Card variant="outlined" sx={{ position: 'relative', display: 'flex', mx: '13px', mt: '10px', width: '100%', maxWidth: '350px' }}>
  {showRemove && (
    <Box sx={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }}>
      <CardActions onClick={() => removeProduct(product)} style={{ background: 'rgba(255, 255, 255, 0.8)', borderRadius: '50%', padding: '4px', cursor: 'pointer' }}>
        <CloseIcon style={{ color: "#D2268D" }} /> 
      </CardActions>
    </Box>
  )}
  <Box sx={{ display: 'flex', flexDirection: 'column', flex: '5 5 auto', transform: 'scale(0.8)' }}>
    <CardContent>
      <Typography component="div" variant="h6">
        {product.name}
      </Typography>
      <Typography variant="body1">Size: {product.size}</Typography>
      <Typography variant="body1">Quantity: {product.count}</Typography>
      <Typography variant="body1">Price: {product.price * product.count} </Typography>
    </CardContent>
  </Box>
  <CardMedia
    component="img"
    sx={{ width: 90 }}
    image={ `${process.env.REACT_APP_BE_BASE_URL}${product.img}`}
    alt={product.name}
  />
</Card>

        );
      })}
      <Grid item xs={12}>
        <Typography variant="body1">Your Total Amount: {total}</Typography>
      </Grid>
    </Grid>
  );
};
