import { combineReducers } from '@reduxjs/toolkit';
import productReducer from './product';
import userReducer from './user';
import sortingReducer from './sorting';
import addressReducer from './address';

const rootReducer = combineReducers({
  product: productReducer,
  user: userReducer,
  sorting: sortingReducer,
  addresses: addressReducer
});

export default rootReducer;