import { useState, useEffect } from 'react';
import { TextField, Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { ContactService } from '../service/contact.service';
import AddressCard from '../components/Address';
import Loader from '../components/Loader';
import { useGoogleLogin } from '../components/GoogleLoginContext';

interface ContactData {
  name: string;
  email: string;
  phone: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
}
const contactService = new ContactService(process.env.REACT_APP_BE_BASE_URL || '');
export const ContactForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<ContactData>({
    name: '',
    email: '',
    phone: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: ''
  });
  const { googleLoginState } = useGoogleLogin();
  const { data, isLoading } = useQuery('contactDetails', () => contactService.getUserContactDetails(
    googleLoginState.userData?.emailId || '',
  ), {
    enabled: googleLoginState && googleLoginState.isAuthenticated && !!googleLoginState.userData
  });

  useEffect(() => {
    if (!isLoading && data && data?.success && data?.data) {
    // @ts-ignore
    const { name, email, phone, addressLine1, addressLine2, city, state, zip } = data.data;
    setFormData({
      name,
      email,
      phone,
      addressLine1,
      addressLine2,
      city,
      state,
      zip
    });
  
    }
  }, [data, isLoading]);
  


  const handleChange = (e: { target: { name: any; value: any; }; }) => {
    setFormData({ ...formData, 
    email: googleLoginState?.userData?.emailId || '',
    [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    // Add your form submission logic here
    const contactInfo  = await contactService.contactDetails(formData);
    console.log(contactInfo, '---------')
    if(contactInfo?.success) {
      handleContinue()
    } else {
      alert('Some error occured while saving contact info. Please try after sometime.')
    }
  };

  const handleContinue = () => {
    navigate('/confirm');
  }

  return (
    <>
    {isLoading && <Loader />}
    {!isLoading && data && !data?.data && <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={formData.name}
            required
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            label="Email"
            name="email"
            type="email"
            disabled={true}
            value={googleLoginState.userData?.emailId}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            label="Phone"
            name="phone"
            type="tel"
            value={formData.phone}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            label="Address Line 1"
            name="addressLine1"
            value={formData.addressLine1}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            label="Address Line 2"
            name="addressLine2"
            value={formData.addressLine2}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            label="City"
            name="city"
            value={formData.city}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            label="State"
            name="state"
            value={formData.state}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            label="ZIP Code"
            name="zip"
            value={formData.zip}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" type="submit">
            Submit
          </Button>
        </Grid>
      </Grid>
    </form> }
    {!isLoading && data?.data && formData?.email && (
      <>
      <AddressCard
        address={{
          name: formData.name, 
          email: formData.email,
          phone: formData.phone,
          addressLine1: formData.addressLine1,
          addressLine2: formData.addressLine2,
          city: formData.city,
          state: formData.state,
          zip: formData.zip
        }}
      />
      <Button onClick={handleContinue}>Continue</Button>
      </>
    )}
    </>
  );
};