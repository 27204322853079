export const urls = {
    GET_LOGGED_USER: "/users",
    SAVE_USER_DETAILS: "/users",
    GET_CONTACT_DETAILS: "/contact",
    SAVE_CONTACT_DETAILS: "/contact",
    GET_ORDER_DETAILS: "/orders",
    SAVE_ORDER_DETAILS: "/orders",
    GET_PRODUCT: "/product",
    SAVE_PRODUCT: "/product",
    GET_CART_DETAILS: "/cart",
    SAVE_CART_DETAILS: "/cart",
  };
  