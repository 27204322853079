import { AppBar, Toolbar, IconButton, Typography, useTheme } from '@mui/material';
import { Menu as MenuIcon, ChevronLeft as ChevronLeftIcon } from '@mui/icons-material';
import Button from '@mui/joy/Button';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Badge from '@mui/material/Badge';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { AccountMenu } from './Account';


import { useDrawerContext } from '../contexts/drawer-context';

export const Header = () => {
  const { isOpened, toggleIsOpened } = useDrawerContext();
  const theme = useTheme();

  const product = useSelector((state: RootState) => state.product)

  let pCount = 0;
  product.forEach((p) => pCount = pCount + p.count);
  console.log(pCount)
  return (
    <AppBar
      sx={{ backgroundColor: '#132954', color: 'secondary.contrastText' }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          onClick={() => toggleIsOpened(!isOpened)}
          sx={{ padding: theme.spacing(1) }}
        >
          {isOpened ? <ChevronLeftIcon /> : <MenuIcon />}
        </IconButton>
        <Typography variant="h6" sx={{ ml: '0.5rem', display:'flex', flexGrow:1 }}>
         <Link to="/">
          <img src={process.env.PUBLIC_URL+"/logo.png"} className="App-logo" alt="logo" style={{width: "40px", marginTop: "6px", borderRadius: "2px"}} /> 
          </Link>
        </Typography>
          <Link to="/cart">
          <Badge badgeContent={pCount} color="primary" invisible={pCount === 0}>
          <Button variant="solid" size="sm" sx={{ height: "35px", width: "35px", background: "#D2268D", alignContent: "flex-end", marginRight: "2px",
          '&:hover': {
            backgroundColor: 'common.white',
            color: '#D2268D',
          }}} >
            <ShoppingCartIcon />
          </Button>
          </Badge>
          </Link>
        <AccountMenu />
      </Toolbar>
    </AppBar>
  );
};

