import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Address {
  id: number;
  name: string;
  street: string;
  city: string;
  state: string;
  zip: string;
}

interface AddressState {
  addresses: Address[];
}

const initialState: AddressState = {
  addresses: [],
};

const addressSlice = createSlice({
  name: 'addresses',
  initialState,
  reducers: {
    addAddress: (state, action: PayloadAction<Address>) => {
      state.addresses.push(action.payload);
    },
    updateAddress: (state, action: PayloadAction<Address>) => {
      const { id, ...newAddress } = action.payload;
      const index = state.addresses.findIndex((address) => address.id === id);
      if (index !== -1) {
        state.addresses[index] = { ...state.addresses[index], ...newAddress };
      }
    },
  },
});

export const { addAddress, updateAddress } = addressSlice.actions;
export default addressSlice.reducer;
