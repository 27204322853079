import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Confetti from 'react-confetti';

const PlaceOrderPage: React.FC = () => {
  const navigate = useNavigate();

  const continueShopping = () => {
    navigate('/')
  }

  return (
    <>
     
        <p>Hurray! Your order has been confirmed!</p>
        <Confetti />
       
        <Button onClick={continueShopping}>Continue Shopping</Button>
     
    </>
  );
};

export default PlaceOrderPage;
