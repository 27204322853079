import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query';
//import { useState, useEffect } from 'react';
import { Typography, Grid } from '@mui/material';
import { ProductService } from '../service/product.service'
import Loader from '../components/Loader';

// interface Product {
//     id: number;
//     name: string;
//     description: string;
//     price: number;
//     img: string;
//     quantity: number;
//     createdOn: string;
//     type: string;
//   }

const productService = new ProductService(process.env.REACT_APP_BE_BASE_URL || '');  
const ProductPage = () => {
  const { pid } = useParams<{ pid: string }>();
 // const [product, setProduct] = useState<Product | undefined>(undefined);

  // useEffect(() => {
  //   productService.getProduct(pid);

  //   // For demonstration purposes, using a dummy product
  //   const currentProduct = products.data.find(({ id }) => id === +pid!);
  //   setProduct(currentProduct);
  //   console.log(pid);
  // }, [pid]);

  const { data, isLoading } = useQuery('contactDetails', () => productService.getProduct(
    pid || '',
  ), {
    enabled: !!pid
  });

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
    {data?.data && <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        {/* Product Image Gallery */}
        <img src={`${process.env.REACT_APP_BE_BASE_URL}/${data?.data?.img}`} alt={data?.data?.name} style={{ width: '100%' }} />
        {/* You can implement a gallery component here */}
      </Grid>
      <Grid item xs={12} md={6}>
        {/* Product Information */}
        <Typography variant="h5" gutterBottom>{data?.data?.name}</Typography>
        <Typography variant="body1" gutterBottom>{data?.data?.description}</Typography>
        <Typography variant="h6" gutterBottom>&#8377;  {data?.data?.price}</Typography>
        {/* <Typography variant="subtitle1" gutterBottom>Available Sizes: {product?.sizes?.join(', ')}</Typography>
        <Typography variant="subtitle1" gutterBottom>Available Colors: {product?.colors?.join(', ')}</Typography>
        <Typography variant="subtitle2" gutterBottom>Rating: {product?.rating} ({product?.reviews} reviews)</Typography> */}
        {/* Add to Cart/Wishlist Buttons */}
        {/* <Button variant="contained" color="primary">Add to Cart</Button>
        <IconButton color="primary" aria-label="Add to Wishlist">
          {/* Add wishlist icon here */}
        {/* </IconButton>  */}
        {/* You can also include related products section here */}
      </Grid>
    </Grid> }
    {
      !data?.data && <Typography>No product found...</Typography>
    }
    </> 
  );
};

export default ProductPage;
