import { Typography } from '@mui/material';
 
export function NoProductFound() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh', marginLeft: '20px' }}>
      <div>
        <Typography variant="h5" gutterBottom>
          No products found
        </Typography>
        <Typography variant="body1">
          Sorry, we couldn't find any products matching your search criteria.
        </Typography>
      </div>
    </div>
  );
}